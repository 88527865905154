// Libraries
import * as React from 'react'

// Components
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import DuoSection from '../../components/duoSection'
import Button from "../../components/button"

class DonatePage extends React.Component {
	render() {
		return <Layout className="donation nav-blue-half no-footer">
			<Seo title="donation" />
			<DuoSection success Donation>
				<div>
					<div className="content-wrap">
						<h1>Thank you for your donation</h1>
						<p>Your donation has been sent, thank you so much for supporting Concerts for Carers. We have
							many more events coming up and we hope you will continue to enter ballots to win tickets.</p>
						<Button to="/events" hollow colorEndeavour>Explore<span className="d-none d-sm-inline"> More</span> Events</Button>
					</div>
				</div>
				<div>
				</div>
			</DuoSection>
		</Layout>
	}
}

export default DonatePage
